import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Privacy Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">Last updated: February 07, 2024</h5>
                  </div>
                  <h4>Interpretation</h4>
                  <p>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                  <h4>Definitions</h4>
                  <p className="mb--20">
                    For the purposes of this Privacy Policy:
                  </p>
                  <ul>
                    <li>
                      <strong>Account</strong> means a unique account created
                      for You to access our Service or parts of our Service.
                    </li>
                    <li>
                      <strong>Affiliate</strong> means an entity that controls,
                      is controlled by or is under common control with a party,
                      where "control" means ownership of 50% or more of the
                      shares, equity interest or other securities entitled to
                      vote for election of directors or other managing
                      authority.
                    </li>
                    <li>
                      <strong>Company</strong> (referred to as either "the
                      Company", "We", "Us" or "Our" in this Agreement) refers to
                      Chromnyx.
                    </li>
                    <li>
                      <strong>Cookies</strong> are small files that are placed
                      on Your computer, mobile device or any other device by a
                      website, containing the details of Your browsing history
                      on that website among its many uses.
                    </li>
                    <li>
                      <strong>Country</strong> refers to: Victoria, Australia
                    </li>
                    <li>
                      <strong>Personal Data</strong> is any information that
                      relates to an identified or identifiable individual.
                    </li>
                    <li>
                      <strong>Service</strong> refers to the Website.
                    </li>
                    <li>
                      <strong>Service Provider</strong> means any natural or
                      legal person who processes the data on behalf of the
                      Company. It refers to third-party companies or individuals
                      employed by the Company to facilitate the Service, to
                      provide the Service on behalf of the Company, to perform
                      services related to the Service or to assist the Company
                      in analyzing how the Service is used.
                    </li>
                    <li>
                      <strong>Usage Data</strong> refers to data collected
                      automatically, either generated by the use of the Service
                      or from the Service infrastructure itself (for example,
                      the duration of a page visit).
                    </li>
                    <li>
                      <strong>Website</strong> refers to Chromnyx, accessible
                      from{" "}
                      <a href="www.chromnyx.com" target="_blank">
                        www.chromnyx.com
                      </a>
                    </li>
                    <li>
                      <strong>You</strong> means the individual accessing or
                      using the Service, or the company, or other legal entity
                      on behalf of which such individual is accessing or using
                      the Service, as applicable.
                    </li>
                  </ul>
                  <h4>Types of Data Collected</h4>
                  <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Usage Data</li>
                  </ul>
                  <h4>Why we collect and use personal data</h4>
                  <p className="mb--20">
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy.
                  </p>
                  <ul>
                    <li>
                      To provide and maintain our Service, including to monitor
                      the usage of our Service.
                    </li>
                    <li>
                      To manage Your Account: to manage Your registration as a
                      user of the Service. The Personal Data You provide can
                      give You access to different functionalities of the
                      Service that are available to You as a registered user
                    </li>
                    <li>
                      For the performance of a contract: the development,
                      compliance and undertaking of the purchase contract for
                      the products, items or services You have purchased or of
                      any other contract with Us through the Service.
                    </li>
                    <li>
                      To contact You: by email, telephone calls, SMS, or other
                      equivalent forms of electronic communication, such as a
                      mobile application's push notifications regarding updates
                      or informative communications related to the
                      functionalities, products or contracted services,
                      including the security updates, when necessary or
                      reasonable for their implementation.
                    </li>
                    <li>
                      To provide You with news, special offers and general
                      information about other goods, services and events which
                      we offer that are similar to those that you have already
                      purchased or enquired about unless You have opted not to
                      receive such information.
                    </li>
                    <li>
                      To manage Your requests: To attend and manage Your
                      requests to Us.
                    </li>
                    <li>
                      For business transfers: We may use Your information to
                      evaluate or conduct a merger, divestiture, restructuring,
                      reorganization, dissolution, or other sale or transfer of
                      some or all of Our assets, whether as a going concern or
                      as part of bankruptcy, liquidation, or similar proceeding,
                      in which Personal Data held by Us about our Service users
                      is among the assets transferred.
                    </li>
                    <li>
                      For other purposes: We may use Your information for other
                      purposes, such as data analysis, identifying usage trends,
                      determining the effectiveness of our promotional campaigns
                      and to evaluate and improve our Service, products,
                      services, marketing and your experience.
                    </li>
                  </ul>
                  <h4>The use of cookies and web beacons</h4>
                  <p className="mb--20">
                    We may log information using "cookies." Cookies are small
                    data files stored on your hard drive by a website. Cookies
                    help us make our Site and your visit better.
                  </p>
                  <p className="mb--20">
                    May log information using digital images called web beacons
                    on our Site or in our emails.
                  </p>
                  <p>
                    This information is used to make our Site work more
                    efficiently, as well as to provide business and marketing
                    information to the owners of the Site, and to gather such
                    personal data as browser type and operating system,
                    referring page, path through site, domain of ISP, etc. for
                    the purposes of understanding how visitors use our Site.
                    Cookies and similar technologies help us tailor our Site to
                    your personal needs, as well as to detect and prevent
                    security threats and abuse. If used alone, cookies and web
                    beacons do not personally identify you.
                  </p>
                  <h4>How long we keep your data</h4>
                  <p className="mb--20">
                    We store personal data for as long as we find it necessary
                    to fulfill the purpose for which the personal data was
                    collected, while also considering our need to answer your
                    queries or resolve possible problems. This helps us to
                    comply with legal requirements under applicable laws, to
                    attend to any legal claims/complaints, and for safeguarding
                    purposes.
                  </p>
                  <p>
                    We store personal data for as long as we find it necessary
                    to fulfill the purpose for which the personal data was
                    collected, while also considering our need to answer your
                    queries or resolve possible problems. This helps us to
                    comply with legal requirements under applicable laws, to
                    attend to any legal claims/complaints, and for safeguarding
                    purposes.
                  </p>
                  <h4>Your rights to your personal data</h4>
                  <p>
                    We store personal data for as long as we find it necessary
                    to fulfill the purpose for which the personal data was
                    collected, while also considering our need to answer your
                    queries or resolve possible problems. This helps us to
                    comply with legal requirements under applicable laws, to
                    attend to any legal claims/complaints, and for safeguarding
                    purposes.
                  </p>
                  <h4>Changes to this Privacy Policy</h4>
                  <p className="mb--20">
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                  </p>
                  <p className="mb--20">
                    We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the "Last updated" date at the top of this Privacy
                    Policy.
                  </p>
                  <p>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </p>
                  <h4>Contact Us</h4>
                  <p className="mb--20">
                    If you have any questions about this Privacy Policy, You can
                    contact us at{" "}
                    <a href="mailto:info@chromnyx.com">info@chromnyx.com</a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
